(ns io.klei.lms.frontend.feature.registration.existing-student
  (:require
    [goog.object :as gobject]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [io.klei.lms.frontend.shared.re-frame.pathom :as pathom]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.utils :as utils]
    [io.klei.lms.frontend.shared.route-utils :as r.utils]))

(defn register-existing-student-form [student-id]
  (let [student @(rf/subscribe [:entity.student.sub/one-by-id student-id])
        level-options @(rf/subscribe [:feat.registration.existing-student.sub/level-options])
        section-options @(rf/subscribe [:feat.registration.existing-student.sub/section-options])
        ay-options @(rf/subscribe [:feat.registration.existing-student.sub/academic-year-options])
        selected-academic-year-id @(rf/subscribe [:feat.registration.existing-student.sub/selected-academic-year-id])
        submitting? @(rf/subscribe [:feat.registration.existing-student.sub/submitting?])
        fetch-state @(rf/subscribe [:feat.registration.existing-student.sub/fetch-ay-state])
        form (atom nil)]
    (when (= :done fetch-state)
      [ui/form {:ref #(reset! form %)
                :labelCol {:xs {:span 24}
                           :sm {:span 3}}
                :wrapperCol {:xs {:span 24}
                             :sm {:span 8}}
                :initialValues #js {:academic-year-id selected-academic-year-id}
                :onFinish (fn [values]
                            (rf/dispatch [:feat.registration.existing-student.event/on-submit @form values student-id]))}
       [ui/form-item
        {:label "Learner No."
         :name "student-number"}
        [ui/text (:student/student-number student)]]

       [ui/form-item
        {:label "First Name"
         :name "first-name"}
        [ui/text (:person/first-name student)]]

       [ui/form-item
        {:label "Last Name"
         :name "last-name"}
        [ui/text (:person/last-name student)]]

       [ui/form-item
        {:label "Current Level"
         :name "current-level"}
        [ui/text (-> student :student/latest-registration :registration/level :level/name)]]


       [ui/form-item
        {:label "Current Section"
         :name "current-section"}
        [ui/text (-> student :student/latest-registration :registration/section :section/name)]]

       [ui/form-item
        {:label "School"
         :name "school-code"
         :rules [{:required true}]}
        [ui/select {:options [{:value "agca"
                               :label "Angeli Gilbert Christian Academy"}
                              {:value "jrss"
                               :label "Jesus the Risen Savior School"}
                              {:value "vecc"
                               :label "Victoria Elijah Christian College"}
                              {:value "saint-paul"
                               :label "Saint Paul University"}
                              {:value "srk"
                               :label "San Roque Kids Academy"}]}]]

       [ui/form-item
        {:label "Academic Year"
         :name "academic-year-id"
         :rules [{:required true}]}
        [ui/select {:options ay-options
                    :onChange (fn [val]
                                (rf/dispatch [:feat.registration.existing-student.event/change-academic-year val])
                                (.setFieldsValue @form #js {:level-id nil
                                                            :section-id nil}))}]]

       [ui/form-item
        {:label "Grade Level"
         :name "level-id"
         :rules [{:required true}]}
        [ui/select {:options level-options
                    :on-change (fn [val]
                                 (rf/dispatch [:feat.registration.existing-student.event/on-level-change val])
                                 (.setFieldsValue @form #js {:section-id nil}))}]]

       [ui/form-item
        {:label "Section"
         :name "section-id"
         :rules [{:required true}]}
        [ui/select {:options section-options
                    :on-change #(rf/dispatch [:feat.registration.existing-student.event/on-section-change %])
                    :not-found-content (r/as-element [ui/text "No available section for the selected level."])}]]

       [ui/form-item {:wrapperCol {:span 11}}
        [ui/button {:type "primary"
                    :htmlType "submit"
                    :style {:margin-left "auto"
                            :display "block"}
                    :loading submitting?}
         "Register"]]])))

(rf/reg-event-fx
  :feat.registration.existing-student.event/get-student
  (fn [{:keys [db]} [_ student-id]]
    {:dispatch [:entity.student.event/get-by-school-id]}))


(defn- submitting [db val]
  (assoc db :feat.registration.existing-student.db/submitting? val))

(def academic-year-pull-pattern
  [:academic-year/id
   :academic-year/name
   :academic-year/start-date
   :academic-year/end-date
   :academic-year/state
   {:academic-year/sections
    [:section/id
     :section/name
     :section/level]}])

;;------------------------------------------------------------
;; EVENTS
(rf/reg-event-fx
  :feat.registration.existing-student.event/on-submit
  (fn [{db :db} [_ form values student-id]]
    (when-let [date (gobject/get values "date-of-birth")]
      (gobject/set values "date-of-birth" (.format date "YYYY-MM-DD")))
    {:db (submitting db true)
     :http-xhrio
     (utils/http-map db {:method :post
                         :uri (str "/registration/student/existing/" student-id)
                         :params values
                         :on-success [:feat.registration.existing-student.event/on-submit-success form]
                         :on-failure [:feat.registration.existing-student.event/on-submit-failure form]})}))

(rf/reg-event-fx
  :feat.registration.existing-student.event/on-submit-success
  (fn [{db :db} [_ form]]
    {:db (submitting db false)
     :antd/form-reset form
     :dispatch [:toast-notification {:type :success
                                     :message "Student has been successfully registered"}]}))

(rf/reg-event-fx
  :feat.registration.existing-student.event/on-submit-failure
  (fn [{db :db} [_ form failure]]
    {:db (submitting db false)
     :dispatch [:toast-notification {:type :error
                                     :message (-> failure :response :message)}]}))

(rf/reg-event-db
  :feat.registration.existing-student.event/set-form
  (fn [db [_ form]]
    (assoc db :feat.registration.existing-student.db/form form)))

(rf/reg-event-db
  :feat.registration.existing-student.event/on-level-change
  (fn [db [_ level-id]]
    (-> db
        (assoc :feat.registration.existing-student.db/selected-level-id level-id)
        (assoc :feat.registration.existing-student.db/selected-section-id nil))))

(rf/reg-event-db
  :feat.registration.existing-student.event/on-section-change
  (fn [db [_ section-id]]
    (assoc db :feat.registration.existing-student.db/selected-section-id section-id)))

(rf/reg-event-fx
  :feat.registration.existing-student.event/change-academic-year
  (fn [{:keys [db]} [_ ay-id]]
    {:dispatch [::pathom/query {:entity {:academic-year/id ay-id}
                                :eql academic-year-pull-pattern
                                :on-success [:feat.registration.existing-student.event/set-academic-year]}]}))

(defn- set-academic-year [db ay]
  (assoc db :feat.registration.existing-student.db/academic-year ay))

(rf/reg-event-fx
  :feat.registration.existing-student.event/set-academic-year
  (fn [{:keys [db]} [_ response]]
    {:db (set-academic-year db response)}))

(rf/reg-event-fx
  :feat.registration.existing-student.event/get-academic-year-sections
  (fn [{:keys [db]} [_]]
    (let [match (:router.db/match db)
          school-id (r.utils/school-id match)]
      {:db (assoc db :feat.registration.existing-student.db/fetch-ay-state :in-flight)
       :dispatch [::pathom/query {:entity {:school/id school-id}
                                  :eql [{:school/current-enrollment-academic-year
                                         academic-year-pull-pattern}]
                                  :on-success [:feat.registration.existing-student.event/get-academic-year-sections-success]}]})))

(rf/reg-event-fx
  :feat.registration.existing-student.event/get-academic-year-sections-success
  (fn [{:keys [db]} [_ response]]
    {:db (-> db
             (set-academic-year (:school/current-enrollment-academic-year response))
             (assoc :feat.registration.existing-student.db/fetch-ay-state :done))}))

;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :feat.registration.existing-student.sub/fetch-ay-state
  (fn [db _]
    (get db :feat.registration.existing-student.db/fetch-ay-state)))

(rf/reg-sub
  :feat.registration.existing-student.sub/submitting?
  (fn [db _]
    (get db :feat.registration.existing-student.db/submitting? false)))

(rf/reg-sub
  :feat.registration.existing-student.sub/form
  (fn [db _]
    (get db :feat.registration.existing-student.db/form)))

(rf/reg-sub
  :feat.registration.existing-student.sub/academic-year
  (fn [db _]
    (get db :feat.registration.existing-student.db/academic-year)))

(rf/reg-sub
  :feat.registration.existing-student.sub/academic-year-sections
  :<- [:feat.registration.existing-student.sub/academic-year]
  (fn [ay]
    (-> ay :academic-year/sections)))

(rf/reg-sub
  :feat.registration.existing-student.sub/section-options
  :<- [:feat.registration.existing-student.sub/academic-year-sections]
  :<- [:feat.registration.existing-student.sub/selected-level-id]
  (fn [[sections level-id] _]
    (some->> sections
             (filter (fn [section]
                       (= (some-> section :section/level :level/id) level-id)))
             (map (fn [section]
                    {:value (:section/id section)
                     :text (:section/name section)
                     :label (:section/name section)})))))

(rf/reg-sub
  :feat.registration.existing-student.sub/academic-year-options
  :<- [:entity.academic-year.sub/select-options]
  (fn [ay _]
    ay))

(rf/reg-sub
  :feat.registration.existing-student.sub/selected-level-id
  (fn [db _] (get db :feat.registration.existing-student.db/selected-level-id)))

(rf/reg-sub
  :feat.registration.existing-student.sub/selected-section-id
  (fn [db _] (get db :feat.registration.existing-student.db/selected-section-id)))

(rf/reg-sub
  :feat.registration.existing-student.sub/selected-academic-year-id
  :<- [:feat.registration.existing-student.sub/academic-year]
  (fn [ay]
    (:academic-year/id ay)))

(rf/reg-sub
  :feat.registration.existing-student.sub/level-options
  :<- [:entity-by-id :level/id]
  (fn [levels _]
    (some->> levels
             (sort-by (fn [[_k level]]
                        (:level/order level)))
             (map (fn [[_k level]]
                    {:value (:level/id level)
                     :text (:level/name level)
                     :label (:level/name level)})))))

