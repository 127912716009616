(ns io.klei.lms.frontend.feature.registration.by-date
  (:require
    [goog.string :as gstring]
    [goog.string.format]
    [reagent.core :as r]
    [re-frame.core :as rf]
    [goog.object :as gobject]
    [clojure.string :as string]
    [io.klei.lms.frontend.entity.current-user :as entities.current-user]
    [io.klei.lms.frontend.entity.user :as e.user]
    [io.klei.lms.frontend.entity.student :as e.student]
    [io.klei.lms.frontend.entity.registration :as entities.registration]
    [io.klei.lms.frontend.shared.re-frame.pathom :as pathom]
    [io.klei.lms.frontend.shared.icon :as icon]
    [io.klei.lms.frontend.shared.ui :as ui]
    [io.klei.lms.frontend.shared.route-utils :as r.utils]
    [io.klei.lms.frontend.shared.utils :as utils]))

(defn registration-table []
  (let [match @(rf/subscribe [:router.sub/match])
        data-source @(rf/subscribe [:feat.registration.by-date.sub/registrations-table-data])
        total @(rf/subscribe [:feat.registration.by-date.sub/total])
        reg-filter @(rf/subscribe [:feat.registration.by-date.sub/filter])]
    [:<>
     [ui/table {:className "kl-table"
                :columns [{:title "Learner No."
                           :dataIndex ["student" "number"]
                           :key "student"}
                          {:title "Student"
                           :dataIndex ["student" "name"]
                           :key "student"
                           :render (fn [text ^js record]
                                     (r/as-element
                                       [:a
                                        {:class "kl-table__link"
                                         :href (r.utils/page-href :page.user/single
                                                                  (merge (r.utils/org+school-params match)
                                                                         {:user-id (gobject/getValueByKeys record "user" "id")})
                                                                  {:role "student"})}
                                        text]))}
                          {:title "Section"
                           :dataIndex ["section" "name"]
                           :key "section"}
                          {:title "Level"
                           :dataIndex ["level" "name"]
                           :key "level"}
                          {:title "State"
                           :dataIndex "state"
                           :key "state"
                           :render (fn [text ^js record]
                                     (r/as-element [ui/tag {:color (if (= (gobject/get record "state") "registered")
                                                                     "success"
                                                                     "default")} text]))}
                          {:title "Registration Date"
                           :dataIndex "registration-date"
                           :key "registration-date"}
                          {:render (fn [text ^js record]
                                     (r/as-element
                                       [ui/space {:direction "horizontal"}
                                        [ui/button {:type "link"
                                                    :onClick #(rf/dispatch [:feat.registration-edit.event/open
                                                                            (gobject/getValueByKeys record "registration-id")
                                                                            (gobject/getValueByKeys record "academic-year" "id")
                                                                            (gobject/getValueByKeys record "level" "id")])}
                                         "Edit"]
                                        [ui/button {:type "link"
                                                    :onClick #(rf/dispatch [:router.event/navigate
                                                                            :page.school-admin/register-existing-student
                                                                            {:path-params (merge
                                                                                            (r.utils/org+school-params match)
                                                                                            {:student-id (gobject/getValueByKeys record "student" "id")})}])}
                                         "Register"]]))}]
                :dataSource data-source
                :pagination {:total total
                             :defaultCurrent (gobject/get utils/default-pagination "current")
                             :defaultPageSize (gobject/get utils/default-pagination "pageSize")}
                :onChange (fn [pagination _filters _sorter _extra]
                            (when-not (= (:pagination reg-filter) pagination)
                              (rf/dispatch [:feat.registration.by-date.event/set-filter (assoc reg-filter :pagination pagination)])))}]]))

;;------------------------------------------------------------
;; UTILS
(defn filter-registration-dispatch [filter']
  [:feat.registration.by-date.event/get-by-date-range {:filter {:student-number (:student-number filter')
                                                                :start-date (when (empty? (:student-number filter'))
                                                                              (utils/format-date (first (:date-range filter'))))
                                                                :end-date (when (empty? (:student-number filter'))
                                                                            (utils/format-date (second (:date-range filter'))))}
                                                       :pagination {:offset (* (dec (gobject/get (:pagination filter') "current"))
                                                                               (gobject/get (:pagination filter') "pageSize"))
                                                                    :limit (gobject/get (:pagination filter') "pageSize")}}])

;;------------------------------------------------------------
;; EVENTS
(rf/reg-event-fx
  :feat.registration.by-date.event/get-by-date-range
  (fn [{db :db} [_ args]]
    {:dispatch [::pathom/query {:eql [{(list :registration/all args)
                                       [{:pagination/edges [:registration/id
                                                             :registration/date
                                                             :registration/state
                                                             :registration/student
                                                             :registration/academic-year-id
                                                             {:registration/section [:section/id
                                                                                     :section/name]}
                                                             {:registration/level [:level/id
                                                                                   :level/name]}]}
                                         :pagination/total]}]
                                :on-success [:feat.registration.by-date.event/get-by-date-range-success]}]}))

(rf/reg-event-fx
  :feat.registration.by-date.event/get-by-date-range-success
  (fn [{db :db} [_ response]]
    {:db (-> db
             (utils/add {:feat.registration.by-date.db/registrations (-> response :registration/all :pagination/edges)})
             (assoc :feat.registration.by-date.db/total (-> response :registration/all :pagination/total)))}))

(rf/reg-event-fx
  :feat.registration.by-date.event/set-filter
  (fn [{db :db} [_ filter']]
    {:db (assoc db :feat.registration.by-date.db/filter filter')
     :dispatch (filter-registration-dispatch filter')}))

;;------------------------------------------------------------
;; SUBS
(rf/reg-sub
  :feat.registration.by-date.sub/registrations
  (fn [db _]
    (let [reg-ids (->> (get db :feat.registration.by-date.db/registrations)
                       (map second))]
      (->> (utils/pull-many db :registration/id reg-ids entities.registration/registration-pull-pattern)
           ;; TODO: not needed anymore? they are sorted from resolvers
           #_(sort-by :registration/date #(compare %2 %1))))))

(rf/reg-sub
  :feat.registration.by-date.sub/total
  (fn [db _]
    (get db :feat.registration.by-date.db/total)))

(rf/reg-sub
  :feat.registration.by-date.sub/filter
  (fn [db _]
    (get db :feat.registration.by-date.db/filter)))

(rf/reg-sub
  :feat.registration.by-date.sub/registrations-table-data
  :<- [:feat.registration.by-date.sub/registrations]
  :<- [:feat.registration.by-date.sub/filter]
  (fn [[registrations table-filter] _]
    (let [sn-filter (or (:student-number table-filter) "")]
      (cond->> registrations
               (seq sn-filter)
               (filter (fn [reg]
                         (when-let [sn (some-> reg :registration/student :student/student-number string/lower-case)]
                           (string/includes? sn (string/lower-case sn-filter)))))
               :always
               (mapv (fn [reg]
                       {:key (:registration/id reg)
                        :registration-id (:registration/id reg)
                        :registration-date (utils/format-datetime (:registration/date reg))
                        :state (:registration/state reg)
                        :user {:id (-> reg :registration/student :auth/user-id)}
                        :academic-year {:id (-> reg :registration/academic-year-id)}
                        :level {:id (-> reg :registration/level :level/id)
                                :name (-> reg :registration/level :level/name)}
                        :student {:id (-> reg :registration/student :student/id)
                                  :name (e.student/last-first-name (-> reg :registration/student))
                                  :number (-> reg :registration/student :student/student-number)
                                  :gender (some-> reg :registration/student :person/gender string/upper-case)
                                  :email (-> reg :registration/student :auth/user :user/email)}
                        :section {:id (-> reg :registration/section :section/id)
                                  :name (-> reg :registration/section :section/name)}}))))))
